import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    page: { type: String, default: 'landing_page' }
  }

  connect() {
    this.resize()
  }

  resize() {
    if (this.pageValue === 'landing_page') {
      let projectDiv = $('.project-div')
      projectDiv.height(projectDiv.width())
    } else if (this.pageValue === 'projects') {
      let projectDiv = $('.portfolio-project')
      projectDiv.height(projectDiv.height())
    }
  }
}
